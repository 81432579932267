<template>
  <div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card>
      <vs-table stripe pagination :max-items="10" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="updateProduct" class="mr-6 mt-5">Importar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()

export default {
  name: 'ImportProducts',
  components: {
    ImportExcel
  },
  data() {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      categories: [],
      brands: [],
      products: []
    }
  },
  async created() {
    await this.loadProducts()
  },
  methods: {
    loadDataInTable({results, header, meta}) {
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    },
    async importData() {
      try {
        let operationCounter = 0
        const batchArray = []
        batchArray.push(firebase.firestore().batch())
        let batchIndex = 0
        this.initProgress = true
        this.tableData.forEach((p) => {
          const productRef = db.collection('products').doc(p.ID)
          batchArray[batchIndex].update(productRef, {
            position: p.BOOST || 0
          })
          operationCounter++
          if (operationCounter === 499) {
            batchArray.push(firebase.firestore().batch());
            batchIndex++;
            operationCounter = 0;
          }
        })
        batchArray.forEach(async batch => await batch.commit())
        this.$vs.notify({
          color: 'success',
          title: 'Carga de información',
          text: 'Importación realizada correctamente.'
        })
        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    async updateProduct() {
      try {
        let operationCounter = 0
        const batchArray = []
        batchArray.push(firebase.firestore().batch())
        let batchIndex = 0
        this.initProgress = true
        this.products.forEach((p) => {
          const productRef = db.collection('products').doc(p.id)
          batchArray[batchIndex].update(productRef, {
            slug: p.slug.replace('/', '-').replace('/', '-')
          })
          operationCounter++
          if (operationCounter === 499) {
            batchArray.push(firebase.firestore().batch());
            batchIndex++;
            operationCounter = 0;
          }
        })
        batchArray.forEach(async batch => await batch.commit())
        this.$vs.notify({
          color: 'success',
          title: 'Carga de información',
          text: 'Importación realizada correctamente.'
        })
        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    getSlug(name) {
      console.log(name)
      let text = (name).trim().split(' ').join('-').toLowerCase().normalize('NFD')
          .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
          .normalize();
      return text
    },
    loadProducts() {
      return new Promise((resolve, rejected) => {
        db.collection("products").orderBy('position', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let product = {
              id: doc.id,
              slug: doc.data().slug
            }
            this.products.push(product)
          })
          resolve('ok')
          this.$store.dispatch('products/fetchProducts', {products: this.productsPre})
        }).catch((error) => {
          rejected(error)
        })
      })
    }
  }
}
</script>
